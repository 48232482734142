export const config = {
  apiBaseUrl: window._env_.REACT_APP_API_BASE_URL,
  tokenStorageKey: '@MetaForce:userToken',
  identityBaseUrl: window._env_.REACT_APP_IDENTITY_BASE_URL,
  centerpointBaseUrl: window._env_.REACT_APP_CENTERPOINT_BASE_URL,
  centerpointUiBaseUrl: window._env_.REACT_APP_CENTERPOINT_UI_BASE_URL,
  productLicense: '61de8cb5e123ded81bd71035',
  docsUrl: window._env_.REACT_APP_DOCUMENTATION_URL
};

// export const oidcConfig = {
//   client_id: 'centerpoint_web',
//   redirect_uri: 'http://localhost:3000/authentication/callback',
//   response_type: 'code',
//   post_logout_redirect_uri: 'http://localhost:3000',
//   scope: 'openid profile email api.internal offline_access',
//   authority: 'https://identity.stage.metaforcelabs.com',
//   silent_redirect_uri: 'http://localhost:3000/authentication/silent_callback',
//   automaticSilentRenew: true,
//   loadUserInfo: true,
// };

export const oidcConfig = {
  client_id: 'audit_web',
  redirect_uri: window._env_.REACT_APP_REDIRECT_URI,
  response_type: 'code',
  post_logout_redirect_uri: window._env_.REACT_APP_POST_LOGOUT_REDIRECT_URI,
  scope: 'openid profile email api.internal offline_access',
  authority: window._env_.REACT_APP_AUTHORITY,
  silent_redirect_uri: window._env_.REACT_APP_SILENT_REDIRECT_URI,
  automaticSilentRenew: true,
  loadUserInfo: true
};

//export const oidcConfig = {
//   client_id: 'centerpoint_web',
//   redirect_uri: 'https://dialog.stage.metaforcelabs.com/authentication/callback',
//   response_type: 'code',
//   post_logout_redirect_uri: 'https://dialog.stage.metaforcelabs.com/',
//   scope: 'openid profile email api.internal offline_access',
//   authority: 'https://identity.stage.metaforcelabs.com',
//   silent_redirect_uri: 'https://dialog.stage.metaforcelabs.com/authentication/silent_callback',
//   automaticSilentRenew: true,
//   loadUserInfo: true,
// };
